import React, { useState } from 'react'
import './index.css'

function Welcome() {
  const [style, setStyle] = useState({
    transform: 'rotateX(0deg) rotateY(0deg)',
    boxShadow: '0 0 15px rgb(var(--arcoblue-2))',
  });

  const handleMouseMove = (e) => {
    const rect = e.currentTarget.getBoundingClientRect();
    const x = e.clientX - rect.left; // 获取鼠标相对于容器左上角的水平位置
    const y = e.clientY - rect.top;  // 获取鼠标相对于容器左上角的垂直位置

    const rotateX = (y / rect.height - 0.5) * 20; // 根据鼠标位置计算 X 轴旋转角度
    const rotateY = (x / rect.width - 0.5) * -20; // 根据鼠标位置计算 Y 轴旋转角度

    const shadowX = (x / rect.width - 0.5) * -30; // 根据鼠标位置计算 X 轴阴影偏移
    const shadowY = (y / rect.height - 0.5) * 30;  // 根据鼠标位置计算 Y 轴阴影偏移

    setStyle({
      transform: `rotateX(${rotateX}deg) rotateY(${rotateY}deg)`,
      boxShadow: `${shadowX}px ${shadowY}px 20px rgb(var(--arcoblue-2))`,
    });
  };

  const handleMouseLeave = () => {
    setStyle({
      transform: 'rotateX(0deg) rotateY(0deg)',
      boxShadow: '0 0 15px rgb(var(--arcoblue-2))',
    });
  };

  return (
    <div
      className="tilt-box"
      style={style}
      onMouseMove={handleMouseMove}
      onMouseLeave={handleMouseLeave}
    >
      <div className="content">
        <h2>Work Lite</h2>
        <p>我来！免费的图片处理工具！</p>
      </div>
    </div>
  );
}

export default Welcome