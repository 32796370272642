import React, { useState } from 'react';
import { Outlet, useNavigate } from "react-router-dom";
import { Layout, Tooltip, Button } from '@arco-design/web-react';
import { IconWechat, IconMoonFill, IconSunFill } from '@arco-design/web-react/icon';
import AppMenu from './components/AppMenu'
import './App.css'
import qrcodeImg from './assets/images/qrcode.jpg'

// const SubMenu = Menu.SubMenu;
const Sider = Layout.Sider;
const Header = Layout.Header;
const Footer = Layout.Footer;
const Content = Layout.Content;

function QrCode() {
  return (
    <div className='qrcode_x'>
      <img className='qrcode_img' src={qrcodeImg} />
    </div>
  )
}

function App() {

  const navigate = useNavigate();

  // 缩放左侧菜单
  const [ collapsed, setCollapsed ] = useState(false)
  function handleCollapsed(broken) {
    // console.log(broken)
    setCollapsed(broken)
    // setCollapsed(!collapsed)
  }

  // 主题
  const [theme, setTheme] = useState('')

  // 切换主题
  function changeTheme() {
    let t = theme === '' ? 'dark' : '';
    document.body.setAttribute('arco-theme', t);
    setTheme(t);
  }

  const goToHomePage = () => {
    navigate('/'); // 跳转到首页
  };


  return (
    <Layout className='worklite_layout' style={{height: '100%'}}>
      {/* <Sider collapsed={collapsed} collapsible trigger={null} breakpoint='xl'> */}
      <Sider collapsed={collapsed} collapsible trigger={null} breakpoint='xl' onBreakpoint={handleCollapsed}>
        <h2 className='app_logo' onClick={goToHomePage}>
          {collapsed ? <span>我来</span> : <span>Work Lite</span>}
        </h2>
        <AppMenu />
      </Sider>
      <Layout style={{height: '100%'}}>
        <Header className='app_header'>
          {/* <Button shape='round' className='trigger' onClick={handleCollapsed}>
            {collapsed ? <IconCaretRight /> : <IconCaretLeft />}
          </Button> */}
          <div className='app_nav_aria_label'>
          <Tooltip position='bottom' trigger='hover' content={<QrCode />}>
            <IconWechat className='app_nav_aria_label_icon wechat' />
          </Tooltip>
          
          {
            theme === '' ?
              <IconSunFill className='app_nav_aria_label_icon' onClick={changeTheme} /> :
              <IconMoonFill className='app_nav_aria_label_icon' onClick={changeTheme} />
          }
          </div>
        </Header>
        <Layout style={{height: '100%', overflow: 'hidden'}}>
          <Content className='app_content'>
            <Outlet></Outlet>
          </Content>
          <Footer className='app_footer'>
            <a href='https://beian.miit.gov.cn/' target="_blank" rel="noreferrer">粤ICP备2024304581号-1</a>
          </Footer>
        </Layout>
      </Layout>
    </Layout>
  )
}

export default App;
